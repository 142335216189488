import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import textStyles from "../styles/textStyles"

// Components
import SEO from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <StyledContentWrapper>
      <Title>404...</Title>
      <Text>
        Het lijkt er op dat deze pagina niet (meer) bestaat. Keer terug naar{" "}
        <Link to="/">home</Link>.
      </Text>
    </StyledContentWrapper>
  </>
)

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`

const Title = styled.h1`
  ${textStyles.title};
`

const Text = styled.p`
  :last-child {
    margin-bottom: 0;
  }
`

export default NotFoundPage
